import { Stripe } from "@stripe/stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { Dispatch, SetStateAction, useEffect } from "react";

import { useResult } from "../../../../organisms/result/use-cases/result-use-cases";
import { Locale } from "../../../../settings/countries";
import { getStripePublishableKey } from "../stripe";

export const useLoadStripeLibrary = (setStripePromise: Dispatch<SetStateAction<Stripe>>): void => {
  const result = useResult();
  const { i18n } = useTranslation();
  const country = result?.country;
  const locale = i18n.language as Locale;

  useEffect(() => {
    if (!country) {
      return;
    }

    const loadStripeLibrary = async (): Promise<void> => {
      try {
        const stripePublishableKey = getStripePublishableKey(country);
        const stripe = await loadStripe(stripePublishableKey, { locale });

        if (!stripe) {
          return;
        }

        setStripePromise(stripe);
      } catch (error) {
        console.error("Error loading Stripe:", error);
      }
    };

    void loadStripeLibrary();
  }, [country, locale, setStripePromise]);
};
