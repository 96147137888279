import cn from "classnames";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { ReactElement } from "react";

import Heading from "../../../../../atoms/heading/Heading";
import { useResult } from "../../../../../organisms/result/use-cases/result-use-cases";
import { PetSpecies } from "../../../../../settings/pet";
import { formatCurrency } from "../../../../../utils/currency";
import { formatDate } from "../../../../../utils/date";
import { useShoppingCart } from "../../../application/shopping-cart-use-cases";
import { PaymentIntervalMonths } from "../../../settings/payments";
import CheckoutSummaryHeader from "../../atoms/checkout-summary-header/CheckoutSummaryHeader";
import CheckoutProductsList from "../../molecules/checkout-products-list/CheckoutProductsList";
import CheckoutExpandable from "../checkout-expandable/CheckoutExpandable";
import CheckoutSummaryFooter from "../checkout-summary-footer/CheckoutSummaryFooter";
import * as styles from "./CheckoutSummary.module.scss";

interface Image {
  default: string;
}

interface CheckoutSummaryProps {
  adoptionClassName?: string;
}

const catSVG = require("../../images/pages/checkout/injured-cat.svg") as Image;
const dogSVG = require("../../images/pages/checkout/injured-dog.svg") as Image;

const catSVGSrc: string = catSVG.default;
const dogSVGSrc: string = dogSVG.default;

const CheckoutSummary = ({ adoptionClassName }: CheckoutSummaryProps): ReactElement => {
  const { i18n } = useTranslation();
  const cart = useShoppingCart();
  const result = useResult();

  const hasTrialPeriod = result?.hasPolicyStartDateAfterTomorrow ?? null;
  const isYearlyPayment = cart && cart.paymentIntervalMonths === PaymentIntervalMonths.yearly;
  const totalPrice = formatCurrency(
    isYearlyPayment ? cart.selectedProductsTotalPrice : cart.selectedProductsMonthlyTotalPrice,
    i18n.language,
    cart.currency
  );
  const paymentAmount = formatCurrency(
    cart.paymentAmount > 0 ? cart.paymentAmount : 0,
    i18n.language,
    cart.currency
  );

  return (
    <>
      {result && (
        <div className={cn(styles.checkoutSummaryWrapper, adoptionClassName)}>
          <div className={styles.imageWrapper}>
            <img
              src={result?.pet_species === PetSpecies.dog ? dogSVGSrc : catSVGSrc}
              alt=""
              width="100"
              height="100"
              loading={"lazy"}
            />
          </div>
          <Heading level={1} adoptionClassName={styles.paymentAmount}>
            {paymentAmount}
          </Heading>
          <CheckoutSummaryHeader
            {...(hasTrialPeriod && { hasTrialPeriod })}
            isYearlyPayment={isYearlyPayment}
            price={totalPrice}
            policyStartDate={formatDate(result.policy_start_date, i18n.language)}
          />

          <div className={styles.mobileWrapper}>
            <CheckoutExpandable
              {...(hasTrialPeriod && { hasTrialPeriod })}
              products={cart.selectedProducts}
              species={result.pet_species}
              subtotalPrice={totalPrice}
              paymentAmount={paymentAmount}
              countryCodeIso={result.country}
            />
          </div>

          <div className={styles.desktopWrapper}>
            <CheckoutProductsList products={cart.selectedProducts} species={result.pet_species} />
            <CheckoutSummaryFooter
              countryCodeIso={result.country}
              subtotalPrice={totalPrice}
              paymentAmount={paymentAmount}
              {...(hasTrialPeriod && { hasTrialPeriod })}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default CheckoutSummary;
