import { useTranslation } from "gatsby-plugin-react-i18next";
import { ReactElement } from "react";

import Expandable from "../../../../../atoms/expandable/Expandable";
import { CountryCode } from "../../../../../settings/countries";
import { PetSpecies } from "../../../../../settings/pet";
import { Product } from "../../../domain/product";
import CheckoutProductsList from "../../molecules/checkout-products-list/CheckoutProductsList";
import CheckoutSummaryFooter from "../checkout-summary-footer/CheckoutSummaryFooter";
import * as styles from "./CheckoutExpandable.module.scss";

interface CheckoutExpandableProps {
  products: Product[];
  species: PetSpecies;
  subtotalPrice: string;
  paymentAmount: string;
  countryCodeIso: CountryCode;
  hasTrialPeriod?: boolean;
}

const CheckoutExpandable = ({
  products,
  species,
  subtotalPrice,
  paymentAmount,
  countryCodeIso,
  hasTrialPeriod,
}: CheckoutExpandableProps): ReactElement => {
  const { t } = useTranslation();

  return (
    <Expandable
      id={"checkout-expandable"}
      expandText={t("common.see_more")}
      collapseText={t("common.see_less")}
      adoptionClassName={styles.checkoutExpandable}
    >
      <CheckoutProductsList products={products} species={species} />
      <CheckoutSummaryFooter
        countryCodeIso={countryCodeIso}
        subtotalPrice={subtotalPrice}
        paymentAmount={paymentAmount}
        {...(hasTrialPeriod && { hasTrialPeriod })}
      />
    </Expandable>
  );
};

export default CheckoutExpandable;
