import { useElements, useStripe } from "@stripe/react-stripe-js";
import { StripeError } from "@stripe/stripe-js";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { FormEvent, useCallback, useState } from "react";

import { useResult } from "../../../../organisms/result/use-cases/result-use-cases";
import { Events, track } from "../../../../utils/analytics";
import { getPaymentMethodsAvailables } from "../../../../utils/locale-configuration-utils";
import { handleConfirmIntentStripeErrors } from "../../utils/error-utils/catch-error-handlers";
import { useSetupIntent } from "./useSetupIntent";

interface UseCheckoutForm {
  handleSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
  errorMessage: string | undefined;
  isLoading: boolean;
  isReady: boolean;
  handleReady: () => void;
}

export const useCheckoutForm = (): UseCheckoutForm => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const result = useResult();
  const { getOrCreateClientSecret } = useSetupIntent();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isReady, setIsReady] = useState<boolean>(false);

  const isInvalidToSubmit = !stripe || !elements || !result || !result.isResultValidToCheckout;

  const handleStripeError = useCallback(
    (error: StripeError): void => {
      track(Events.ERROR_OCCURRED, {
        error: "Error submitting the checkout form",
        type: error.type,
        code: error.code,
      });

      handleConfirmIntentStripeErrors(error, error.message || t("common.error_message"));
      setErrorMessage(error.message || t("common.error_message"));
    },
    [t]
  );
  const handleReady = useCallback(() => {
    setIsReady(true);
  }, []);

  const submitPaymentElement = useCallback(async () => {
    if (isInvalidToSubmit) {
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setErrorMessage(submitError.message ?? t("common.error_message"));

      return;
    }
  }, [elements, isInvalidToSubmit, t]);

  const confirmSetupIntent = useCallback(
    async (clientSecret: string) => {
      if (isInvalidToSubmit) {
        return;
      }

      const { error: confirmIntentError } = await stripe.confirmSetup({
        elements,
        clientSecret,
        confirmParams: {
          return_url: `${process.env.GATSBY_URL_BASE}/results/${result.uuid}/checkout_success/`,
        },
      });

      if (confirmIntentError) {
        handleStripeError(confirmIntentError);

        return;
      }
    },
    [elements, isInvalidToSubmit, result?.uuid, stripe, handleStripeError]
  );

  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>): Promise<void> => {
      e.preventDefault();
      if (isInvalidToSubmit) {
        return;
      }

      setIsLoading(true);
      track(Events.CLICKED_CTA, { eventSender: "Checkout page contract CTA" });

      try {
        await submitPaymentElement();
        const paymentMethodsTypes = getPaymentMethodsAvailables(result.country);
        const clientSecret = await getOrCreateClientSecret(
          result?.pet_parent_kb_key,
          paymentMethodsTypes
        );
        await confirmSetupIntent(clientSecret);
      } catch (error) {
        handleStripeError(error);
      } finally {
        setIsLoading(false);
      }
    },
    [
      isInvalidToSubmit,
      submitPaymentElement,
      getOrCreateClientSecret,
      confirmSetupIntent,
      handleStripeError,
      result,
    ]
  );

  return {
    handleSubmit,
    errorMessage,
    isLoading,
    isReady,
    handleReady,
  };
};
