import { graphql } from "gatsby";
import { ReactElement } from "react";

import withFunnelWrapper from "../../../features/quotes-funnel/ui/organisms/with-funnel-wrapper/with-funnel-wrapper";
import Checkout from "../../../features/quotes-funnel/ui/pages/checkout/Checkout";
import { PageId } from "../../../settings/pages";

const Page = (): ReactElement => <Checkout />;

export default withFunnelWrapper(Page, "pg-checkout", PageId.checkout);

export const query = graphql`
  query CheckoutPageQuery {
    locales: allLocale {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
