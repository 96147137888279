import { createSetupIntent, createSubscription } from "../data/checkout-repository";

export const useCreateSubscription = (): ((
  resultUuid: string,
  setupIntentId: string,
  promotionCodeId?: string
) => Promise<Record<string, string>>) => {
  return (resultUuid, setupIntentId, promotionCodeId) => {
    return createSubscription(
      resultUuid,
      JSON.stringify({
        setup_intent_id: setupIntentId,
        ...(promotionCodeId && { promotion_code_id: promotionCodeId }),
      })
    );
  };
};

export const useCreateSetupIntent = (): ((
  petParentKbKey: string,
  paymentMethodsTypes: string[]
) => Promise<Record<string, string>>) => {
  return (petParentKbKey, paymentMethodsTypes) => {
    return createSetupIntent(
      JSON.stringify({ lead_kb_key: petParentKbKey, payment_method_types: paymentMethodsTypes })
    );
  };
};
