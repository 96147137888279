import { useTranslation } from "gatsby-plugin-react-i18next";
import { ReactElement } from "react";

import { usePromotionCode } from "../../../application/promotion-code-use-cases";
import * as styles from "./CheckoutSummaryHeader.module.scss";

interface CheckoutSummaryHeaderProps {
  hasTrialPeriod?: boolean;
  isYearlyPayment: boolean | null;
  price: string;
  policyStartDate: string;
}

const CheckoutSummaryHeader = ({
  hasTrialPeriod = false,
  isYearlyPayment,
  price,
  policyStartDate,
}: CheckoutSummaryHeaderProps): ReactElement => {
  const { t } = useTranslation();
  const promotionCode = usePromotionCode();

  const priceExplanation = () => {
    const paymentFrequency = isYearlyPayment ? "yearly" : "monthly";

    return t(
      `checkout_summary.header.payment_${
        promotionCode ? "after" : "without"
      }_promotion_code_explanation.${paymentFrequency}`,
      {
        amount: price,
      }
    );
  };

  return (
    <div className={styles.checkoutSummaryHeader}>
      {hasTrialPeriod ? (
        <>
          <p>{t("checkout_summary.header.first_payment", { policyStartDate })}</p>
          <p>{t("checkout_summary.header.policy_start_date", { policyStartDate })}</p>
          <p>{priceExplanation()}</p>
        </>
      ) : (
        <p>{priceExplanation()}</p>
      )}
    </div>
  );
};

export default CheckoutSummaryHeader;
