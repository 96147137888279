import cn from "classnames";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { ReactElement } from "react";

import { CountryCode } from "../../../../../settings/countries";
import { formatCurrency } from "../../../../../utils/currency";
import { usePromotionCode } from "../../../application/promotion-code-use-cases";
import { useShoppingCart } from "../../../application/shopping-cart-use-cases";
import PromotionCodeForm from "../promotion-code-form/PromotionCodeForm";
import * as styles from "./CheckoutSummaryFooter.module.scss";

interface CheckoutSummaryFooterProps {
  subtotalPrice: string;
  paymentAmount: string;
  countryCodeIso: CountryCode;
  hasTrialPeriod?: boolean;
}

const CheckoutSummaryFooter = ({
  hasTrialPeriod,
  subtotalPrice,
  paymentAmount,
  countryCodeIso,
}: CheckoutSummaryFooterProps): ReactElement => {
  const { t, i18n } = useTranslation();
  const promotionCode = usePromotionCode();
  const cart = useShoppingCart();

  return (
    <dl className={cn(styles.checkoutPaymentToday)}>
      <dt>{t(`checkout_summary.footer.subtotal`)}</dt>
      <dd>{subtotalPrice}</dd>
      <dt>
        <PromotionCodeForm countryCodeIso={countryCodeIso} showDiscountApplied={false} />
      </dt>
      <dd>
        {promotionCode ? `-${formatCurrency(cart.discount, i18n.language, cart.currency)}` : ""}
      </dd>
      {hasTrialPeriod ? (
        <>
          <dt className={styles.strongText}>{t("checkout_summary.footer.total_due_today")}</dt>
          <dd className={styles.strongText}>{formatCurrency(0, i18n.language)}</dd>
          <dt className={styles.strongText}>{t("checkout_summary.footer.policy_start_date")}</dt>
          <dd className={styles.strongText}>{paymentAmount}</dd>
        </>
      ) : (
        <>
          <dt className={styles.strongText}>{t("checkout_summary.footer.total_due_today")}</dt>
          <dd className={styles.strongText}>{paymentAmount}</dd>
        </>
      )}
    </dl>
  );
};

export default CheckoutSummaryFooter;
