import { useState } from "react";

import { useCreateSetupIntent } from "../checkout-use-cases";

interface UseSetupIntent {
  getOrCreateClientSecret: (
    petParentKbKey: string,
    paymentMethodsTypes: string[]
  ) => Promise<string>;
}

export const useSetupIntent = (): UseSetupIntent => {
  const createSetupIntent = useCreateSetupIntent();
  const [clientSecret, setClientSecret] = useState<string>("");

  const getOrCreateClientSecret = async (petParentKbKey: string, paymentMethodsTypes: string[]) => {
    if (clientSecret.length > 0) {
      return clientSecret;
    }

    const { client_secret } = await createSetupIntent(petParentKbKey, paymentMethodsTypes);
    setClientSecret(client_secret);

    return client_secret;
  };

  return { getOrCreateClientSecret };
};
